import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PeerType = 'guest' | 'agent';

type Peer = {
  call_user_id: string;
  peerJID: string;
  jabberUsername: string;
  PeerType: PeerType;
};

type State = {
  appStatus: 'idle' | 'connected' | 'ready';
  callStarted: boolean;
  selfCallOnHold: boolean; // used to bypass zombie guest
  peerTrackAdded: boolean;
  screenShare: boolean;
  peers: Peer[] | null;
};

const initialState: State = {
  appStatus: 'idle',
  callStarted: false,
  selfCallOnHold: false, // used to bypass zombie guest
  peerTrackAdded: false,
  screenShare: false,
  peers: null
};

const Call_User_Reducer = createSlice({
  name: 'call_user',
  initialState: initialState,
  reducers: {
    toggleCallStarted: (state) => {
      state.callStarted = true;
    },
    addPeer: (state, action: PayloadAction<Peer>) => {
      if (state.peers) {
        // Avoid double adding peers
        if (
          state.peers
            .map(({ peerJID }) => peerJID)
            .includes(action.payload.peerJID)
        ) {
          return;
        }

        state.peers.push(action.payload);
      } else {
        state.peers = [action.payload];
      }
    },
    setPeerTrackAdded: (state, action: PayloadAction<boolean>) => {
      state.peerTrackAdded = action.payload;
    },
    setSelfCallOnHold: (state, action: PayloadAction<boolean>) => {
      state.selfCallOnHold = action.payload;
    },
    setScreenShare: (state, action: PayloadAction<boolean>) => {
      state.screenShare = action.payload;
    },
    setAppStatus: (
      state,
      action: PayloadAction<'idle' | 'connected' | 'ready'>
    ) => {
      const selfCallOnHold = state.selfCallOnHold;

      switch (action.payload) {
        case 'idle': {
          return { ...initialState, selfCallOnHold };
        }
        case 'connected': {
          state.appStatus = 'connected';

          break;
        }
        case 'ready': {
          return { ...initialState, selfCallOnHold, appStatus: 'ready' };
        }
      }
    }
  }
});

export const {
  addPeer,
  setPeerTrackAdded,
  toggleCallStarted,
  setSelfCallOnHold,
  setScreenShare,
  setAppStatus
} = Call_User_Reducer.actions;

export default Call_User_Reducer.reducer;
