import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CallStatusEnum from '../../../enums/CallStatusEnum';

type Agent = {
  username: string;
  jid: string;
  callStatus?: CallStatusEnum;
  peerName?: string;
};

const agents: Agent[] = [];

const AgentRoomReducer = createSlice({
  name: 'agent_list',
  initialState: agents,
  reducers: {
    addAgent: (state, action: PayloadAction<Agent>) => {
      const agent: Agent = {
        callStatus: CallStatusEnum.OpenCall,
        peerName: '',
        ...action.payload
      };
      state.push(agent);
    },
    agentCallStatusUpdate: (
      state,
      action: PayloadAction<{
        jid: string;
        status: CallStatusEnum;
        peerName?: string;
      }>
    ) => {
      let jid = action.payload.jid;
      const index = state.findIndex(
        (item) => item.username === jid.replace(/@.*/g, '')
      );
      console.log('set-jid', action.payload.jid, { index });

      if (index === -1) return;

      state[index].callStatus = action.payload.status;
      action.payload.peerName &&
        (state[index].peerName = action.payload.peerName);
    },
    removeAgent: (state, action: PayloadAction<{ username: string }>) => {
      let username: string = action.payload.username;

      const index = state.findIndex((item) => item.username === username);

      if (index !== -1) state.splice(index, 1);
    }
  }
});

export const {
  addAgent,
  removeAgent,
  agentCallStatusUpdate
} = AgentRoomReducer.actions;

export default AgentRoomReducer.reducer;
