import { makeStyles } from '@material-ui/core/styles';
export const EditDialogStyle = makeStyles({
  formFullWidth: {
    marginTop: 10,
    width: '100%',
    padding: 20,
    border: '2px solid #F3F5FA',
    borderRadius: 12
  },
  fieldGroup: {
    width: '100%'
  },
  submit: {
    background: '#0676ED',
    borderRadius: 20,
    fontSize: 13,
    boxShadow: '3px 3px 10px -1px rgba(11, 27, 102, 0.3)',
    padding: '8px 20px',

    '&:hover': {
      background: '#0676ED'
    }
  },
  reset: {
    marginLeft: 10,
    borderRadius: 20,
    fontSize: 13,
    background: '#FDFEFE',
    color: '#8F9BB3',
    border: '1px solid #DAE1ED',
    boxShadow: 'none',
    padding: '8px 20px',
    '&:hover': {
      background: '#FDFEFE'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  },
  formContainer: {
    color: '#8F9BB3',
    '& label': {
      color: '#8F9BB3',
      paddingLeft: '0.5rem'
    },
    '&  MuiOutlinedInput-root': {
      borderColor: 'red !important'
    },
    borderBottom: '1px solid #DAE1ED',

    paddingBottom: '20px !important',
    '&:nth-of-type(1)': {
      borderRight: '1px solid #DAE1ED',
      paddingRight: '20px !important',
      paddingLeft: '10px !important'
    },
    '&:nth-of-type(2)': {
      paddingRight: '10px !important',
      paddingLeft: '20px !important'
    }
  },
  title: {
    fontSize: 23,
    textAlign: 'center',
    marginBottom: 9
  }
});
