import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const members: string[] = [];

const ZombieGuestReducer = createSlice({
  name: 'zombie_guest_list',
  initialState: members,
  reducers: {
    createZombieGuest: (state, action: PayloadAction<string>) => {
      state.push(action.payload);
    },

    killZombieGuest: (state, action: PayloadAction<string>) => {
      return state.filter((guests) => guests !== action.payload);
    }
  }
});

export const {
  createZombieGuest,
  killZombieGuest
} = ZombieGuestReducer.actions;

export default ZombieGuestReducer.reducer;
