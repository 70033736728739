import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Report } from '../../../services/ReportService';

const initialState: Omit<Report, 'agent_id' | 'agent_ice_error'> = {
  agent_device: '',
  waiting_time: 0,
  call_time: 0,
  success: false,
  terminated_by: 'guest',
  agent_local_sdp: '',
  agent_remote_sdp: ''
};

const ReportReducer = createSlice({
  name: 'report',
  initialState: initialState,
  reducers: {
    setReport: (state, action: PayloadAction<Partial<Report>>) => {
      if (action.payload.success) {
        console.log('REPORT STATS');
      }
      return { ...state, ...action.payload };
    }
  }
});

export const { setReport } = ReportReducer.actions;

export default ReportReducer.reducer;
