import SignIn from './components/SignIn/SignIn.lazy';
import AppHome from './components/AppHome/AppHome.lazy';
import AgentProfile from './components/AgentProfile/AgentProfile';
import { RouteList } from '../src/core/utility/route/routeInterface';

const routes: RouteList[] = [
  {
    exact: true,
    path: '/',
    component: SignIn
  },
  {
    exact: false,
    path: '/agent-login',
    component: SignIn
  },
  {
    exact: false,
    path: '/agent-home',
    component: AppHome
  },
  {
    exact: false,
    path: '/agent-profile',
    component: AgentProfile
  }
];

export default routes;
