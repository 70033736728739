import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CallStatusEnum from '../../../enums/CallStatusEnum';
import { GuestDetails } from '../../interfaces/GuestDetails';

const members: GuestDetails[] = [];

const GuestRoomReducer = createSlice({
  name: 'guest_list',
  initialState: members,
  reducers: {
    setGuest: (state, action) => {
      console.log('%c Set Guest', 'font-size: 15px; color: #32f5e5;');
      let member: GuestDetails = action.payload.guest;
      state.push(member);
    },
    removeGuest: (state, action) => {
      console.log('%c Remove Guest', 'font-size: 15px; color: #32f5e5;');
      let member: string = action.payload.jabberusername;

      const index = state.findIndex((item) => item.jabberusername === member);

      if (index !== -1) state.splice(index, 1);
    },
    callStatusUpdate: (
      state,
      action: PayloadAction<{
        jid: string;
        status: CallStatusEnum;
        peerName?: string;
      }>
    ) => {
      let jid = action.payload.jid;

      const index = state.findIndex(
        (item) => item.jabberusername.replace(/\/.*/g, '') === jid
      );

      console.log(
        '%c Call Status Update Guest',
        'font-size: 15px; color: #32f5e5;'
      );

      if (index === -1) return;

      state[index].callStatus = action.payload.status;
      action.payload.peerName &&
        (state[index].peerName = action.payload.peerName);
    }
  }
});

export const {
  setGuest,
  removeGuest,
  callStatusUpdate
} = GuestRoomReducer.actions;

export default GuestRoomReducer.reducer;
