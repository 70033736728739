import { makeStyles } from '@material-ui/core/styles';
export const AgentProfileStyle = makeStyles((theme) => ({
  profileContainer: {
    marginTop: 120,
    width: '65%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 20,
    border: '2px solid #F3F5FA',
    borderRadius: 12,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    color: '#8F9BB3',
    '& label': {
      color: '#8F9BB3',
      paddingLeft: '0.5rem'
    },
    '&  MuiOutlinedInput-root': {
      borderColor: 'red !important'
    },
    borderBottom: '1px solid #DAE1ED',
    paddingBottom: '20px !important',
    '&:nth-of-type(1)': {
      borderRight: '1px solid #DAE1ED',
      paddingRight: '20px !important',
      paddingLeft: '10px !important'
    },
    '&:nth-of-type(2)': {
      paddingRight: '10px !important',
      paddingLeft: '20px !important'
    }
  },

  gridItem: {
    margin: '0 0 30px 0 ',
    padding: '0 10px'
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  avatarContainer: {
    position: 'relative',
    height: '200px',
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px'
  },
  profileImg: {
    height: '200px',
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
    boxShadow: '0 0 4px 2px #000',
    border: '2px solid #ddd'
  },
  editIcon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    padding: '5px',
    borderRadius: '50%',
    backgroundColor: 'rgb(189,189,189)',
    color: 'black',
    boxShadow: '0 0 8px 0px #000000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'grey',
      boxShadow: '0 0 4px 0px #000000'
    }
  }
}));
