import { Credential } from './models/Credential';
import store from '../store';

export class CredentialService {
  private static auth: Credential = store.getState().agent_auth;

  public static getJid(): string {
    return this.auth.jid;
  }

  public static getPassword(): string {
    return this.auth.username;
  }

  public static getCompanyIds(): number[] {
    return this.auth.companies;
  }

  public static getCategoryIds(): number[] {
    return this.auth.categories;
  }

  public static getUserName(): string {
    return this.auth.username;
  }

  public static isWildCard(): boolean {
    return this.auth.isWildCard;
  }
}
