import React, { lazy, Suspense } from 'react';
import { useSelector } from '../../lib/useSelector';

import { Loader } from '../Loader';

const LazySignIn = lazy(() => import('./SignIn'));
const SignIn = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => {
  const isLoggedIn = useSelector((state) => state.agent_auth.isLoggedIn);

  if (isLoggedIn) {
    window.location.href = '/agent-home';
    return <Loader />
  }

  return (
    <Suspense fallback={<Loader />}>
      <LazySignIn {...props} />
    </Suspense>
  );
};

export default SignIn;
