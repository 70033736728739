import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import RouteGenerator from './core/utility/route/route.generator';
import 'webrtc-adapter';
import { VideoRecordingSync } from './services/VideoRecordSync';

function App() {
  useEffect(() => {
    const tick = setInterval(
      VideoRecordingSync,
      1000 * 60 * 2 // 2 minutes
    );

    return () => clearInterval(tick);
  }, []);

  let routes = RouteGenerator.getRoute();
  const routeComponents = routes.map(({ path, exact, component }, key) => (
    <Route exact={exact} path={path} component={component} key={key} />
  ));

  return <main id="app_main">{routeComponents}</main>;
}

export default App;
