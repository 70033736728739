import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  isLoggedIn: boolean;
  token: string;
  jid: string;
  id: number;
  password: string;
  username: string;
  categories: string[];
  companies: string[];
  isWildCard: boolean;
  name: string;
  email: string;
  profileImageUrl: string;
};

const CREDENTIALS = 'ANTILLIA_CREDENTIALS';
const localState = localStorage.getItem(CREDENTIALS);

const initialState: State = localState
  ? JSON.parse(localState)
  : {
      isLoggedIn: false,
      token: '',
      jid: '',
      id: 0,
      password: '',
      username: '',
      categories: [],
      companies: [],
      isWildCard: false,
      name: '',
      email: '',
      profileImageUrl: ''
    };

const AgentAuthReducer = createSlice({
  name: 'agent_auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Omit<State, 'isLoggedIn'>>) => {
      const newStore = { ...action.payload, isLoggedIn: true };
      localStorage.setItem(CREDENTIALS, JSON.stringify(newStore));
      return newStore;
    },

    logout: (state, action: PayloadAction<void>) => {
      localStorage.removeItem(CREDENTIALS);
      return initialState;
    },

    update: (state, action: PayloadAction<Partial<State>>) => {
      const newStore = { ...state, ...action.payload };
      localStorage.setItem(CREDENTIALS, JSON.stringify(newStore));
      return newStore;
    }
  }
});

export const { login, logout, update } = AgentAuthReducer.actions;

export default AgentAuthReducer.reducer;
