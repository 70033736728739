import React from 'react';
import { Toolbar, Avatar, Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { CredentialService } from '../../services/CredentialService';
import { topBarStyles } from './Style';
import AppLogo from '../../assets/img/antilla-logo.png';
import ProfilePic from '../../assets/img/profile_photo.svg';
import { logout } from '../../store/reducers/agent/AgentAuthReducer';
import { useDispatch } from 'react-redux';
import { AgentMonitor } from '../../services/ReportService';
import { useHistory } from 'react-router-dom';

export default function PrimarySearchAppBar() {
  const classes = topBarStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();
  const userName = CredentialService.getUserName();

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    dispatch(logout());
    await AgentMonitor.agentActivity({ logOutTime: new Date().toISOString() });
    history.push('/');
  };

  const onProfileClick = () => {
    history.push('/agent-profile');
  };

  const onHomeClick = () => {
    history.push('/agent-home');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={onHomeClick}>Home</MenuItem>
      <MenuItem onClick={onProfileClick}>Profile</MenuItem>
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <Toolbar style={{ padding: 0 }}>
        <img src={AppLogo} alt="Antilla Logo" width="120" />
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Avatar variant="square" src={ProfilePic}></Avatar>
            </Grid>
            <Grid item style={{ paddingLeft: '10px' }}>
              <Typography>{userName}</Typography>
            </Grid>
          </Grid>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <ArrowDropDownIcon />
          </IconButton>
        </div>
      </Toolbar>
      {renderMenu}
    </div>
  );
}
