import routes from '../../../AppRouting.Module';
import { RouteList } from './routeInterface';

class RouteGenerator {
  private static routeList: RouteList[] = [];

  public static getRoute(): RouteList[] {
    return this.generate(routes);
  }

  private static generate(
    primaryRouteList: RouteList[],
    initPath?: string
  ): RouteList[] {
    primaryRouteList.forEach((route) => {
      if (route.children === undefined) {
        if (initPath === undefined) {
          this.routeList.push(route);
        } else {
          route.path = route.path !== '/' ? initPath + route.path : initPath;
          this.routeList.push(route);
        }
      } else {
        initPath !== undefined
          ? this.generate(route.children, initPath + route.path)
          : this.generate(route.children, route.path);
      }
    });
    return this.routeList;
  }
}
export default RouteGenerator;
