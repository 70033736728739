import { createSlice } from '@reduxjs/toolkit';

/**
 * This is a global state to force a component to rerender
 * when some non reactive variable is updated but we need to still enforce reactivity.
 * 
 * Used for Stream management, streams cannot be stored in redux so we use this as a workaround.
 */
const GlobalRerender = createSlice({
  name: 'rerender',
  initialState: {},
  reducers: {
    rerender: (state) => {
      state = {};
    }
  }
});

export const { rerender } = GlobalRerender.actions;

export default GlobalRerender.reducer;
