import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  access_token: '',
  credential: '',
  username: ''
};

const GuestAuthReducer = createSlice({
  name: 'guest_auth',
  initialState: initialState,
  reducers: {
    setGuestAuth: (state, action) => {
      state.access_token = action.payload.access_token;
      state.credential = action.payload.credential;
      state.username = action.payload.username;
    }
  }
});

export const { setGuestAuth } = GuestAuthReducer.actions;

export default GuestAuthReducer.reducer;
