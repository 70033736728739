import axios from 'axios';
import { entries, del } from 'idb-keyval';
import { environment } from '../config/environment';

import type { VideoInfo } from './useStreamRecorder';

const base_url = environment.base_url;

const s3Key = ({ camera, callID, id }: VideoInfo) =>
  `[${camera}]_[${callID}]_[${id}]`;

export const VideoRecordingSync = async () => {
  const videos = (await entries()) as [string, Blob][];

  /**
   * `name` is the name (key) of the video file stored in indexDB
   * `video` is the value, the video file itself.
   * See `useStreamRecorder` to check how they're stored.
   */
  videos.forEach(async ([name, video]) => {
    const info = JSON.parse(name) as VideoInfo;

    if (
      !(typeof name === 'string' && /(host|peer)/.test(name)) ||
      !info.callID
    ) {
      return;
    }

    const payload = {
      call_id: info.callID,
      s3_file_key: s3Key(info),
      content_type: video.type
    };

    type SignedUrlDto = { data: { UploadUrl: string } };

    try {
      const data = await axios.post<SignedUrlDto>(
        base_url + `/reports/signed_url`,
        payload
      );
      const { UploadUrl } = data.data.data;

      await axios.put(UploadUrl, video, {
        headers: { 'Content-Type': video.type }
      });

      const url = new URL(UploadUrl);
      const s3Url = url.origin + url.pathname;

      await axios.put(base_url + '/reports/update', {
        call_id: info.callID,
        [`${info.camera}_video_url`]: s3Url
      });

      del(name);
    } catch (e) {
      console.error(e);
    }
  });
};
