import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField
} from '@material-ui/core';
import axios from 'axios';
import { useState } from 'react';
import { EditDialogStyle } from './EditDialogStyle';
import { environment } from '../../config/environment';
import { update } from '../../store/reducers/agent/AgentAuthReducer';
import { useDispatch } from 'react-redux';

interface Props {
  open: boolean;
  data?: any;
  onClose: () => void;
  inputName: string;
}

const baseUrl = environment.base_url;

const EditDialog: React.FC<Props> = ({ open, onClose, data, inputName }) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const classes = EditDialogStyle();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const { id, token } = data;
      // check if input is profileImageUrl then validate the url
      if (inputName === 'profileImageUrl') {
        const url = value;
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (!urlRegex.test(url)) {
          alert('Please enter a valid url');
          return;
        }
      }

      const res = await axios.patch(
        `${baseUrl}/agents/${id}`,
        {
          [inputName]: value
        },
        {
          headers: {
            'x-auth-token': token
          }
        }
      );
      if (res.status === 200) {
        const credentials = {
          [inputName]: res.data.data[inputName]
        };
        dispatch(update(credentials));
        setValue('');
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return open ? (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <div>
          <Box width={'100%'}>
            <h3 className={classes.title}>
              Edit {inputName === 'name' ? 'Name' : 'Profile Image'}
            </h3>
            <form
              className={`${classes.formFullWidth} ${classes.formContainer}`}
              onSubmit={onSubmit}
            >
              <section className={classes.fieldGroup}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  value={value}
                  label={inputName === 'name' ? 'Name' : 'Profile Image URL'}
                  onChange={(e) => setValue(e.target.value)}
                  autoFocus
                />
              </section>

              <div className={classes.buttonContainer}>
                <Button
                  className={classes.submit}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.reset}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default EditDialog;
