import { combineReducers } from 'redux';
import AgentClientReducer from './agent/AgentClientReducer';
import GuestAuthReducer from './guest/GuestAuthReducer';
import GuestRoomReducer from './room/GuestRoomReducer';
import UserCallReducer from './call/CallReducer';
import ZombieGuestReducer from './room/ZombieGuestReducer';
import AgentRoomReducer from './room/AgentRoomReducer';
import ReportReducer from './report/reportReducer';
import AgentAuthReducer from './agent/AgentAuthReducer';
import GlobalRerender from './rerender';

const reducer = combineReducers({
  agent_client: AgentClientReducer,
  agent_auth: AgentAuthReducer,
  guest_auth: GuestAuthReducer,
  guest_list: GuestRoomReducer,
  agent_list: AgentRoomReducer,
  user_call: UserCallReducer,
  zombie_guests: ZombieGuestReducer,
  report: ReportReducer,
  rerender: GlobalRerender
});

export default reducer;
