import './styles.css';

export const Loader = () => (
  <div
    style={{
      position: 'absolute',
      top: '40%',
      left: '50%'
    }}
  >
    <div
      style={{
        borderTopColor: 'rgb(0 0 0 / 20%)',
        borderBottomColor: 'rgb(0 0 0 / 20%)'
      }}
      className="loader"
    ></div>
    <div style={{ paddingTop: '15px', fontSize: '1.3em', color: '#2e2e2e' }}>
      Loading...
    </div>
  </div>
);
