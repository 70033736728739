import axios from 'axios';
import { environment } from '../config/environment';
const base_url = environment.base_url;

export type Report = {
  agent_id: string;
  agent_device: string;
  waiting_time: number;
  call_time: number;
  terminated_by: 'agent' | 'guest';
  success: boolean;
  agent_ice_error: boolean;
  agent_local_sdp: string;
  agent_remote_sdp: string;
};

class ReportService {
  callID: string;

  /** addReport(){} will be called by Guest, Agent only updates the resource */

  updateReport(data: Partial<Report>) {
    if (!this.callID) return;

    axios
      .put(base_url + '/reports/update', { call_id: this.callID, ...data })
      .catch((err) => console.error(err));
  }

  async getRating(username: string) {
    if (!username) {
      console.error('Could Not get rating');
    }

    type DTO = { data: { rating: number } };
    const data = await axios
      .get<DTO>(base_url + `/reports/rating/${username}`)
      .catch((err) => console.error(err));

    console.log('getReport', data);

    if (data) {
      const rating = data.data.data.rating;
      return rating;
    }

    return null;
  }
}

export const Reporter = new ReportService();

export type AgentActivity = {
  logInTime: string;
  logOutTime: string;
  answeredCall: boolean;
  notReadyTime: string;
  latlong: string;
};

export class AgentMonitor {
  static agentId: number;
  static authToken: string;

  static async agentActivity(data: Partial<AgentActivity>) {
    if (!this.agentId || !this.authToken) return;

    axios
      .patch(base_url + `/agents/monitor/${this.agentId}`, data, {
        headers: { 'x-auth-token': this.authToken }
      })
      .catch((err) => console.error(err));
  }
}
