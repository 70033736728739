import React, { lazy, Suspense, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../lib/useSelector';
import { Loader } from '../Loader';

const LazyAppHome = lazy(() => import('./AppHome'));

const AppHome = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.agent_auth.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);

  return (
    <Suspense fallback={<Loader />}>
      <LazyAppHome {...props} />
    </Suspense>
  );
};

export default AppHome;
