import {
  AppBar,
  Avatar,
  Toolbar,
  Switch,
  FormControlLabel,
  IconButton
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { AgentProfileStyle } from './AgentProfileStyle';
import { Box } from '@material-ui/core';
import { useSelector } from '../../lib/useSelector';
import TopBar from '../TopBar/TopBar';
import { EditRounded } from '@material-ui/icons';
import EditDialog from './EditDialog';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface TextFields {
  name: string;
  username: string;
  email: string;
  profileImageUrl: string;
  isWildCard: boolean;
}

const AgentProfile: React.FC = () => {
  const agent = useSelector((state) => state.agent_auth);
  const history = useHistory();
  const classes = AgentProfileStyle();
  const [editDialog, setEditDialog] = useState({
    inputName: '',
    isOpen: false
  });

  const TextFieldsGroup: Array<{
    label: string;
    name: keyof TextFields;
  }> = [
    {
      label: 'Name',
      name: 'name'
    },
    {
      label: 'Username',
      name: 'username'
    },
    {
      label: 'Email',
      name: 'email'
    }
  ];

  const onEditProfileStart = (name: string) => {
    setEditDialog({ inputName: name, isOpen: true });
  };

  const onEditProfileFinish = () => {
    setEditDialog({ inputName: '', isOpen: false });
  };

  const renderProfile = () => (
    <div className={classes.profileContainer}>
      <div className={classes.avatarContainer}>
        <Avatar
          src={agent && agent.profileImageUrl}
          className={classes.profileImg}
        />
        <IconButton
          onClick={() => onEditProfileStart('profileImageUrl')}
          className={classes.editIcon}
          color="secondary"
          disableRipple
        >
          <EditRounded fontSize="default" />
        </IconButton>
      </div>

      {TextFieldsGroup.map(({ label, name }) => (
        <TextField
          key={name}
          variant="outlined"
          label={label}
          className={classes.gridItem}
          value={agent && agent[name]}
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment:
              name === 'name' ? (
                <EditRounded
                  onClick={() => onEditProfileStart('name')}
                  style={{ cursor: 'pointer' }}
                />
              ) : null
          }}
        />
      ))}

      <FormControlLabel
        control={<Switch checked={agent && agent.isWildCard} color="primary" />}
        label={'Is Wild Card'}
        className={classes.gridItem}
      />
    </div>
  );

  useEffect(() => {
    if (!agent.isLoggedIn) {
      history.push('/');
    }
  }, [history, agent.isLoggedIn]);

  return (
    <div>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ background: 'blue' }}
      >
        <Toolbar>
          <TopBar />
        </Toolbar>
      </AppBar>

      <Box width={'100%'}>{renderProfile()}</Box>

      <EditDialog
        data={agent}
        open={editDialog?.isOpen}
        inputName={editDialog?.inputName}
        onClose={onEditProfileFinish}
      />
    </div>
  );
};

export default AgentProfile;
