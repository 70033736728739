import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  client: null;
  rooms: string[];
}

const initialState: InitialState = {
  client: null,
  rooms: []
};

const agentClientReducer = createSlice({
  name: 'agent_client',
  initialState: initialState,
  reducers: {
    setClientAuth: (state, action) => {
      state.client = action.payload.client;
    },
    setAgentRooms: (state, action) => {
      state.rooms = action.payload;
    }
  }
});

export const { setClientAuth, setAgentRooms } = agentClientReducer.actions;

export default agentClientReducer.reducer;
